*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
.progressContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
}

.progressContainer > span{
    font-size: 6vw;
    font-weight: bold;
    padding-top: 10px;
    background-size: 100%;
}

.pbarContainer{
    width: 92%;
    border: 3px solid;
    border-radius: 4px;
    height: 3rem;
    position: relative;
    margin-bottom: 5px;
    padding: 10px;
}

.pbar {
    position: absolute;
    background: linear-gradient(to right,rgb(244 66 60) 0%, #ffa500 50% ,rgb(28, 192, 56) 100%);
    content: "";
    width: 100%;
    animation: pbar 1s ease-in-out forwards;
    display: flex;
    height: 102%;
    align-items: center;
    top: 0;
    left: -0.5px;
    border-radius: 1px;
    padding: 0px;
}
.percent{
    position: absolute;
    right: calc(var(--value) * 1%);
    font-size: 24px;
    font-weight: bold;
    padding-right: 5px;
}
@keyframes pbar {
    from {
        clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    }
    to {
        clip-path: polygon(0 0, calc(var(--value) * 1%) 0, calc(var(--value) * 1%) 100%, 0 100%);
    }
}

@media only screen and (max-width: 920px) {
    .pbarContainer {
     width: 92%;
    }

    .progressContainer > span{
        font-size: 13vw;
    }
  }

/* @media only screen and (max-width: 863px) {
  
    .progressContainer > span{
        font-size: 10vw;
    }
  } */

  